import { EditIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Tag,
  useDisclosure,
} from '@chakra-ui/react'
import { BaseSyntheticEvent, useEffect, useState } from 'react'
import { IoIosImages } from 'react-icons/io'
import {
  Order_By,
  useGetOrganizationsQuery,
  useUpdateLastOrgContextMutation,
  useUpdateOrgStatusMutation,
} from '../../generated/graphql'
import {
  getOrgData,
  useAuthorizedOrgIds,
  useIsEmployeeView,
  useOrgID,
  useUserID,
} from '../../hooks/id_token_claims'
import {
  DOPPEL_CLOUD_BLUE,
  DOPPEL_ENCRYPT_GREEN_SHADE,
  DOPPEL_FIREWALL_ORANGE,
  DOPPEL_SECURE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../../utils/style'
import InternalDropdown from './internal_dropdown'
import { DoppelTag } from '../shared/doppel_tag'
import { getTabsForOrg, useSelectedReportType } from '@/pages/reports'
import { useRouter } from 'next/router'
import { OrganizationStatus, OrganizationStatusLabels } from '@/generated/enums'

export default function OrgPicker() {
  const orgId = useOrgID()
  const [userId] = useUserID()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const router = useRouter()

  const [orgData, setOrgData] = getOrgData()
  const [filter, setFilter] = useState('') // State to store user input for filtering
  const [debouncedFilter, setDebouncedFilter] = useState(filter) // State to store debounced filter value

  const { data } = useGetOrganizationsQuery({
    variables: {
      orderBy: [{ name: Order_By.Asc }],
    },
  })
  const [isEmployeeView] = useIsEmployeeView()
  const [authorizedOrgIds] = useAuthorizedOrgIds()
  const [updateLastOrgContextMutation, { data: _ }] = useUpdateLastOrgContextMutation()
  const [selectedReportType, handleReportTypeChange] = useSelectedReportType()

  // Update the debounced filter value after a delay
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilter(filter)
    }, 300)

    return () => {
      clearTimeout(handler)
    }
  }, [filter])

  if (!isEmployeeView && authorizedOrgIds.length <= 1) {
    return <InternalDropdown />
  }

  const orgs = data?.organizations || []
  const filteredOrgs = orgs.filter((org) =>
    org.name.toLowerCase().includes(debouncedFilter.toLowerCase()),
  )
  const sortedOrgs = [...filteredOrgs].sort(function (a, b) {
    return orgStatusRank(b) - orgStatusRank(a)
  })

  return (
    <HStack>
      <Tag h="35px">
        <Menu isOpen={isOpen} onClose={onClose} onOpen={onOpen}>
          <Flex alignItems="center" justifyContent="flex-end">
            <MenuButton as={Button} size="xs">
              {orgData.name}
            </MenuButton>

            <Input
              mr={2}
              onChange={(e) => {
                setFilter(e.target.value)
                onOpen()
              }}
              onFocus={() => onOpen()}
              placeholder="Search org"
              size="sm"
              value={filter}
              width="100px"
            />

            <Spacer />
          </Flex>

          <MenuList
            maxHeight="600px"
            onFocus={(e) => {
              // workaround for MenuList stealing focus from input
              // since our usage of the Chakra UI Menu component is non-standard
              if (e.relatedTarget?.className?.includes('chakra-input')) {
                ;(e.relatedTarget as HTMLElement).focus()
              }
            }}
            overflow="scroll"
            zIndex="10"
          >
            <MenuOptionGroup type="radio" value={orgId}>
              {sortedOrgs.map((org) => (
                <MenuItemOption
                  key={org.id}
                  onClick={(e: BaseSyntheticEvent) => {
                    updateLastOrgContextMutation({
                      variables: {
                        last_org_context: org.id,
                        id: userId,
                      },
                    })
                    setOrgData(org)
                    const tabs = getTabsForOrg(org)
                    const currentTabRoute = router.asPath
                    const selectedTab = tabs.find(
                      (tab) => tab.route === currentTabRoute && tab.isVisible,
                    )
                    const firstVisibleTab =
                      selectedTab || tabs.find((tab) => tab.isVisible)
                    if (firstVisibleTab) {
                      handleReportTypeChange(firstVisibleTab.name)
                    }
                  }}
                  value={org.id}
                >
                  <Flex justify={'space-between'} width="120">
                    <span>{org.name}</span>

                    {org.organization_type == 'marketplace' && (
                      <Icon as={IoIosImages} h={5} pl="1" w={5} />
                    )}

                    <Box flexGrow={10} />

                    <DoppelTag
                      bgColor={getColorForOrgStatus(org.organization_status)}
                      color={DOPPEL_WHITE}
                      loading={false}
                      tag={OrganizationStatusLabels[org.organization_status]}
                    />
                  </Flex>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </Menu>
      </Tag>

      <OrgStatusTagWithMenu
        onChange={() => {}}
        orgId={orgData.id}
        orgStatus={orgData.organization_status}
      />

      <InternalDropdown />
    </HStack>
  )
}

function orgStatusRank(org) {
  switch (org.organization_status) {
    case OrganizationStatus.ACTIVE:
      return 5
    case OrganizationStatus.EVALUATING:
      return 4
    case OrganizationStatus.PITCHING:
      return 3
    case OrganizationStatus.TRIAL_ENDED:
      return 2
    case OrganizationStatus.INACTIVE:
      return 1
    case OrganizationStatus.TEST_ORG:
      return 0
  }
}

export function OrgStatusTagWithMenu({
  orgStatus,
  onChange,
  orgId,
}: {
  orgStatus: OrganizationStatus
  onChange: (value: OrganizationStatus) => void
  orgId: string
}) {
  const [updateOrgStatusMutation, { data: updateOrgStatusData, loading, error }] =
    useUpdateOrgStatusMutation()
  const [isEmployeeView] = useIsEmployeeView()
  if (!isEmployeeView) {
    return (
      <DoppelTag
        bgColor={getColorForOrgStatus(orgStatus)}
        color={DOPPEL_WHITE}
        loading={loading}
        tag={OrganizationStatusLabels[orgStatus]}
      />
    )
  }

  // return the tag
  return (
    <Flex>
      <DoppelTag
        bgColor={getColorForOrgStatus(orgStatus)}
        color={DOPPEL_WHITE}
        loading={loading}
        tag={OrganizationStatusLabels[orgStatus]}
      />

      <Menu>
        <MenuButton
          aria-label={''}
          as={IconButton}
          bgColor={DOPPEL_INTERNAL_PURPLE}
          icon={<EditIcon />}
          key="edit"
          marginLeft="2"
          size="xs"
          textColor={DOPPEL_WHITE}
        />

        <MenuList>
          <MenuOptionGroup onChange={onChange} value={orgStatus}>
            {Object.values(OrganizationStatus).map((statusOption) => (
              <MenuItemOption
                key={statusOption}
                onClick={() => {
                  updateOrgStatusMutation({
                    variables: {
                      id: orgId,
                      organization_status: statusOption,
                    },
                  })
                }}
                value={OrganizationStatusLabels[statusOption]}
              >
                <DoppelTag
                  bgColor={getColorForOrgStatus(statusOption)}
                  color={DOPPEL_WHITE}
                  loading={loading}
                  tag={OrganizationStatusLabels[statusOption]}
                />
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export function getColorForOrgStatus(orgStatus) {
  switch (orgStatus) {
    case OrganizationStatus.ACTIVE:
      return DOPPEL_ENCRYPT_GREEN_SHADE
    case OrganizationStatus.TRIAL_ENDED:
      return DOPPEL_SECURE
    case OrganizationStatus.EVALUATING:
      return DOPPEL_FIREWALL_ORANGE
    case OrganizationStatus.TEST_ORG:
      return DOPPEL_INTERNAL_PURPLE
    case OrganizationStatus.PITCHING:
      return DOPPEL_CLOUD_BLUE
    case OrganizationStatus.INACTIVE:
      return DOPPEL_SECURE
  }
}
