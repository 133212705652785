import {
  Order_By,
  Spoofing_Report_Search_Results_Order_By,
  Spoofing_Reports_Order_By,
} from '../../generated/graphql'
import { ReportStatus, ReportType, SCORE_ENABLED_REPORT_TYPES } from '../constants'

export type ReportSortingMechanism = {
  sortingType: SortType
  orderBy: Order_By
}

export function sortingMechanismToString(mechanism: ReportSortingMechanism): string {
  return `${mechanism.sortingType} ${mechanism.orderBy}`
}

export function sortingMechanismFromString(
  sort: string,
): ReportSortingMechanism | null {
  const splitSort = sort.split(' ')
  const sortingType = splitSort[0] as SortType
  const orderBy = splitSort[1] as Order_By
  if (
    Object.values(SortType).includes(sortingType) &&
    Object.values(Order_By).includes(orderBy)
  ) {
    return { sortingType, orderBy }
  }
  return null
}

export enum SortType {
  created_at = 'created_at',
  importance_score = 'importance_score',
  max_similarity_score = 'max_similarity_score',
  num_items = 'num_items',
  volume_traded_opensea = 'volume_traded_opensea',
  spoof_status = 'spoof_status',
  num_followers = 'num_followers',
  online_at = 'online_at',
  last_reported_at = 'last_reported_at',
  last_resolved_at = 'last_resolved_at',
  last_actioned_on = 'last_actioned_on',
  score = 'score',
  approvals = 'approvals',
  price = 'price',
}

export function getOrderByClause(
  searchKey: string | null,
  sortingMechanism: ReportSortingMechanism,
) {
  if (!sortingMechanism || searchKey) return {}
  const sortType = sortingMechanism.sortingType
  if (sortType == SortType.num_items || sortType == SortType.volume_traded_opensea) {
    return { ['flagged_collection']: { [sortType]: sortingMechanism.orderBy } }
  }
  return { [sortingMechanism.sortingType]: sortingMechanism.orderBy }
}

export function defaultSpoofSortingMechanism(
  reportStatus: ReportStatus | null = null,
  isEmployeeView: boolean,
): ReportSortingMechanism {
  const scoreSortDesc = {
    sortingType: SortType.score,
    orderBy: Order_By.DescNullsLast,
  }
  if (reportStatus === ReportStatus.ENUM_INTERNAL_REVIEW) {
    return scoreSortDesc
  }
  if (
    reportStatus === ReportStatus.ENUM_NEEDS_REVIEW ||
    reportStatus === ReportStatus.ENUM_NEEDS_CONFIRMATION
  ) {
    if (isEmployeeView) {
      return scoreSortDesc
    }
    return {
      sortingType: SortType.created_at,
      orderBy: Order_By.DescNullsLast,
    }
  }
  if (reportStatus === ReportStatus.ENUM_REPORTED) {
    return {
      sortingType: SortType.last_reported_at,
      orderBy: Order_By.DescNullsLast,
    }
  } else if (reportStatus === ReportStatus.ENUM_RESOLVED) {
    return {
      sortingType: SortType.last_resolved_at,
      orderBy: Order_By.DescNullsLast,
    }
  }
  if (isEmployeeView) {
    return scoreSortDesc
  }
  return {
    sortingType: SortType.created_at,
    orderBy: Order_By.DescNullsLast,
  }
}

export function defaultSortingMechanism(
  reportStatus: ReportStatus | null = null,
  reportType: ReportType = null,
  isEmployeeView = false,
): ReportSortingMechanism {
  if (reportType !== ReportType.NFTS) {
    return defaultSpoofSortingMechanism(reportStatus, isEmployeeView)
  }
  if (reportStatus === ReportStatus.REPORTED) {
    return {
      sortingType: SortType.last_reported_at,
      orderBy: Order_By.Desc,
    }
  }

  if (reportStatus === ReportStatus.RESOLVED) {
    return {
      sortingType: SortType.last_resolved_at,
      orderBy: Order_By.Desc,
    }
  }
  return {
    sortingType: SortType.max_similarity_score,
    orderBy: Order_By.Desc,
  }
}

export function getSpoofSortTypes(
  reportType: ReportType,
  reportStatus: ReportStatus | null = null,
  isEmployeeView = false,
): Array<SortType> {
  if (reportType == ReportType.DARK_WEB) {
    return [SortType.created_at, SortType.online_at, SortType.spoof_status]
  }
  const sortTypes = [SortType.created_at]
  if (isEmployeeView) {
    sortTypes.push(SortType.last_actioned_on)
  }
  if (reportStatus === ReportStatus.ENUM_REPORTED) {
    sortTypes.push(SortType.last_reported_at)
  }
  if (reportStatus === ReportStatus.ENUM_RESOLVED) {
    sortTypes.push(SortType.last_resolved_at)
  }
  if (reportType == ReportType.SOCIAL_MEDIA) {
    sortTypes.push(SortType.num_followers)
  }
  // TODO: remove SCORE_ENABLED_REPORT_TYPES check once `score` is properly implemented for all report types
  if (isEmployeeView && SCORE_ENABLED_REPORT_TYPES.has(reportType)) {
    sortTypes.push(SortType.score)
  }
  return sortTypes
}

export function getSortTypes(
  reportStatus: ReportStatus | null = null,
): Array<SortType> {
  const sortTypes = [
    SortType.created_at,
    SortType.importance_score,
    SortType.max_similarity_score,
    SortType.num_items,
    SortType.volume_traded_opensea,
  ]
  if (reportStatus === ReportStatus.REPORTED) {
    sortTypes.push(SortType.last_reported_at)
  }
  if (reportStatus === ReportStatus.RESOLVED) {
    sortTypes.push(SortType.last_resolved_at)
  }
  return sortTypes
}

export function basicOrderByClause(selectedSortingMechanism: ReportSortingMechanism) {
  return { [selectedSortingMechanism.sortingType]: selectedSortingMechanism.orderBy }
}

export function spoofingReportsOrderByClause(
  searchKey: string | null,
  selectedSortingMechanism: ReportSortingMechanism,
): Array<Spoofing_Reports_Order_By> {
  if (selectedSortingMechanism.sortingType == SortType.last_actioned_on) {
    return [
      {
        collection_report_changes_aggregate: {
          max: { timestamp: selectedSortingMechanism.orderBy },
        },
      },
    ]
  }
  if (selectedSortingMechanism.sortingType == SortType.num_followers) {
    return [
      {
        social_media_data: { num_followers: selectedSortingMechanism.orderBy },
      },
    ]
  }
  if (selectedSortingMechanism.sortingType == SortType.approvals) {
    return [
      {
        approvals_aggregate: { min: { status: selectedSortingMechanism.orderBy } },
      },
    ]
  }
  if (searchKey || selectedSortingMechanism.sortingType == SortType.score) {
    return [
      { spoof_status: selectedSortingMechanism.orderBy },
      { score: selectedSortingMechanism.orderBy },
      { screenshot_url: Order_By.DescNullsLast },
      { created_at: Order_By.Desc }, // secondary sort by descending date
    ]
  }
  const orderBy: Array<Spoofing_Report_Search_Results_Order_By> = [
    { [selectedSortingMechanism.sortingType]: selectedSortingMechanism.orderBy },
    { screenshot_url: Order_By.DescNullsLast },
    { created_at: Order_By.Desc }, // secondary sort by descending date
  ]
  return orderBy
}

export function sortingMechanismToDisplay(sortType: string): string {
  switch (sortType) {
    case SortType.importance_score: {
      return 'Priority Score'
    }
    case SortType.created_at: {
      return 'Date Created'
    }
    case SortType.last_actioned_on: {
      return 'Date Last Actioned'
    }
    case SortType.num_items: {
      return 'Num Items'
    }
    case SortType.volume_traded_opensea: {
      return 'Volume Traded'
    }
    case SortType.spoof_status: {
      return 'Classification'
    }
    case SortType.num_followers: {
      return 'Num Followers'
    }
    case SortType.online_at: {
      return 'Online At'
    }
    case SortType.last_reported_at: {
      return 'Date Reported'
    }
    case SortType.last_resolved_at: {
      return 'Date Taken Down'
    }
    case SortType.score: {
      return 'Suspicion Score'
    }
    case SortType.price: {
      return 'Price'
    }
    default:
      return 'Similarity Score'
  }
}

export function darkWebSortingMechanismToDisplay(sortType: string): string {
  switch (sortType) {
    case SortType.online_at: {
      return 'Crawled At'
    }
    default:
      return sortingMechanismToDisplay(sortType)
  }
}
