import { Severity, SEVERITY_TO_LABEL } from '@/utils/constants'
import { EnforcementRequestStatus, ReportClassification } from '@/generated/enums'
import {
  DOPPEL_BREACH_RED,
  DOPPEL_FIREWALL_ORANGE,
  DOPPEL_ACTIVE_SILVER,
  DOPPEL_INACTIVE_GREY,
  DOPPEL_BUTTON_GREY,
  DOPPEL_DIM_YELLOW,
  DOPPEL_TEXT_WHITE,
  DOPPEL_CLOUD_BLUE,
  DOPPEL_ENCRYPT_GREEN_SHADE,
} from '../../utils/style'
import { Spinner, Tag } from '@chakra-ui/react'
import { unSnakeCase } from '@/utils/string_utils'

type DoppelTagProps = {
  tag: React.ReactNode
  loading?: boolean
  bgColor?: string
  color?: string
  width?: string
  height?: string
}

export function DoppelTag({
  tag,
  loading,
  bgColor,
  color,
  width,
  height,
}: DoppelTagProps) {
  const defaultBgColor = DOPPEL_BUTTON_GREY
  const defaultColor = DOPPEL_TEXT_WHITE

  return (
    <Tag
      borderRadius="4px"
      fontSize={13}
      fontWeight={600}
      paddingX={2}
      paddingY={1}
      style={{
        ...(width && { width: width }),
        ...(height && { height: height }),
        backgroundColor: bgColor ?? defaultBgColor,
        color: color ?? defaultColor,
      }}
    >
      {loading ? <Spinner size="xs" /> : tag}
    </Tag>
  )
}

type SeverityTagProps = {
  severity: Severity
} & Partial<DoppelTagProps>
const SEVERITY_COLOR_MAP: { [key in Severity]: string } = {
  [Severity.LOW]: DOPPEL_DIM_YELLOW,
  [Severity.MEDIUM]: DOPPEL_FIREWALL_ORANGE,
  [Severity.HIGH]: DOPPEL_BREACH_RED,
}
export function SeverityTag({ severity, ...rest }: SeverityTagProps) {
  if (!severity) return null
  const textColor = DOPPEL_TEXT_WHITE
  const text = `${unSnakeCase(SEVERITY_TO_LABEL[severity])} severity`
  const tagColor = SEVERITY_COLOR_MAP[severity]
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}

type ScoreTagProps = {
  score: number
} & Partial<DoppelTagProps>
const SCORE_COLOR_THRESHOLDS: Array<[number, string]> = [
  [80, DOPPEL_BREACH_RED],
  [50, DOPPEL_FIREWALL_ORANGE],
  [0, DOPPEL_DIM_YELLOW],
]
export function ScoreTag({ score, ...rest }: ScoreTagProps) {
  const computedScore = score ?? 0
  const textColor = DOPPEL_TEXT_WHITE
  const text = computedScore.toFixed(1)
  const [, tagColor] =
    SCORE_COLOR_THRESHOLDS.find(([threshold]) => computedScore >= threshold) ||
    SCORE_COLOR_THRESHOLDS[SCORE_COLOR_THRESHOLDS.length - 1]
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}

type ClassificationTagProps = {
  classification: ReportClassification
} & Partial<DoppelTagProps>
export function ClassificationTag({ classification, ...rest }: ClassificationTagProps) {
  if (!classification) return null
  const [tagColor, textColor] =
    classification == ReportClassification.ACTIVE
      ? [DOPPEL_ACTIVE_SILVER, DOPPEL_TEXT_WHITE]
      : [DOPPEL_INACTIVE_GREY, DOPPEL_TEXT_WHITE]
  const text = unSnakeCase(classification) // will need external label mapping if exists
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}

type EnforcementStatusTagProps = {
  status: string
} & Partial<DoppelTagProps>
const ENFORCEMENT_STATUS_COLOR_MAP = {
  [EnforcementRequestStatus.STAGED]: DOPPEL_ACTIVE_SILVER,
  [EnforcementRequestStatus.REPORTED]: DOPPEL_CLOUD_BLUE,
  [EnforcementRequestStatus.BLOCKED]: DOPPEL_FIREWALL_ORANGE,
  [EnforcementRequestStatus.APPROVED]: DOPPEL_ENCRYPT_GREEN_SHADE,
  [EnforcementRequestStatus.CANCELED]: DOPPEL_INACTIVE_GREY,
  [EnforcementRequestStatus.REJECTED]: DOPPEL_BREACH_RED,
  [EnforcementRequestStatus.RETRACTION_SENT]: DOPPEL_ACTIVE_SILVER,
  [EnforcementRequestStatus.RETRACTED]: DOPPEL_INACTIVE_GREY,
  defaultColor: DOPPEL_INACTIVE_GREY,
}
export function EnforcementStatusTag({ status, ...rest }: EnforcementStatusTagProps) {
  if (!status) return null
  const textColor = DOPPEL_TEXT_WHITE
  const tagColor =
    ENFORCEMENT_STATUS_COLOR_MAP[status] || ENFORCEMENT_STATUS_COLOR_MAP.defaultColor
  const text = unSnakeCase(status)
  return <DoppelTag bgColor={tagColor} color={textColor} tag={text} {...rest} />
}
