import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ChakraProvider } from '@chakra-ui/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import Layout from '../components/layout'
import AuthorizedApolloProvider from '../hooks/authorized_apollo'
import { IDTokenClaimsProvider } from '../hooks/id_token_claims'
import { ReportTableFilterProvider } from '../hooks/report_table_filters'
import { SpoofReportTableFilterProvider } from '../hooks/spoof_report_table_filters'
import theme from '../theme'
import './select_table.css'

import * as gtag from '../gtag'
import { Provider } from 'jotai'
import { SlackNotificationUserOpenedHook } from '../hooks/slack_notifications'
import { ReportsProvider } from './reports'
import { StatsContextProvider } from './stats'
import { EntitiesTableProvider } from '@/hooks/entities_table_filters'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { ErrorBoundary } from '@/components/error_boundary'

const DOPPEL_SCRIPT_NONCE = 'doppel_script'

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  const layout = Component.getLayout || ((page) => <Layout>{page}</Layout>)

  if (typeof window !== 'undefined') {
    // checks that we are client-side
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      capture_pageview: false,
      capture_pageleave: false,
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
      },
    })
  }

  return (
    <>
      <Head>
        <link href="/apple-touch-icon.png" rel="apple-touch-icon" sizes="180x180" />

        <link href="/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />

        <link href="/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />

        <link href="/favicon.ico" rel="shortcut icon" />

        <title>Doppel Vision</title>
      </Head>

      {/* Global Site Tag (gtag.js) - Google Analytics */}

      <Script
        nonce={DOPPEL_SCRIPT_NONCE}
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        strategy="afterInteractive"
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
        id="gtag-init"
        nonce={DOPPEL_SCRIPT_NONCE}
        strategy="afterInteractive"
      />

      <Script
        dangerouslySetInnerHTML={{
          __html: `
          localStorage.removeItem("chakra-ui-color-mode");
          `,
        }}
        id={`chakra-ui-color-mode-reset`}
        nonce={DOPPEL_SCRIPT_NONCE}
        strategy="afterInteractive"
      />

      <Auth0Provider
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE_HASURA}
        cacheLocation="memory"
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
        onRedirectCallback={(appState: AppState) => {
          router.push(appState.auth0RedirectPath)
        }}
        redirectUri={
          process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI ||
          (typeof document !== 'undefined' && document.location.origin)
        }
        useRefreshTokens
      >
        <PostHogProvider client={posthog}>
          <IDTokenClaimsProvider>
            <AuthorizedApolloProvider>
              <ChakraProvider resetCSS theme={theme}>
                <ErrorBoundary locationTag="_app.tsx">
                  <SlackNotificationUserOpenedHook />

                  <ReportsProvider>
                    <StatsContextProvider>
                      <ReportTableFilterProvider>
                        <SpoofReportTableFilterProvider>
                          <EntitiesTableProvider>
                            <Provider>{layout(<Component {...pageProps} />)}</Provider>
                          </EntitiesTableProvider>
                        </SpoofReportTableFilterProvider>
                      </ReportTableFilterProvider>
                    </StatsContextProvider>
                  </ReportsProvider>
                </ErrorBoundary>
              </ChakraProvider>
            </AuthorizedApolloProvider>
          </IDTokenClaimsProvider>
        </PostHogProvider>
      </Auth0Provider>
    </>
  )
}

export default MyApp
