import { useRouter } from 'next/router'
import {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { getOrgData, useIsEmployeeView } from '../hooks/id_token_claims'
import { STAT_DATE_UNIT, StatsTab } from '../utils/constants'
import PageLayout, { TabInfo } from './layout/page_layout'
import moment from 'moment'
import {
  useGetSpoofingReportsStatsLazyQuery,
  useGetSpoofingReportsStatsFilterLazyQuery,
  useGetCollectionReportsStatsV2FilterLazyQuery,
  useGetCollectionReportsStatsV2LazyQuery,
  useGetFilterablePlatformsByProductLazyQuery,
} from '../generated/graphql'
import {
  getReportStatuses,
  getProductsByReportType,
} from '../utils/reports/report_utils'

const StatsContext = createContext(null)

// Number of months for which after the stats will be shown in monthly units
const MONTHLY_AGGREGATION_CUTOFF = 3
const JPM_ORG_NAME = 'JPMorgan Chase'

export function useStartDate() {
  const { startDateOps } = useContext(StatsContext)
  const [startDate, setStartDate] = startDateOps
  return [startDate, setStartDate]
}

export function usePlatformsData() {
  const { platforms } = useContext(StatsContext)
  const [getPlatforms, hasRequestedPlatformData, platformsData, platformsDataLoading] =
    platforms
  return [getPlatforms, hasRequestedPlatformData, platformsData, platformsDataLoading]
}

export function useCollectionReportCountData() {
  const { collectionReportCount } = useContext(StatsContext)
  const [
    getCollectionReportStats,
    hasRequestedCollectionsData,
    collectionReportCountData,
    collectionReportCountDataLoading,
  ] = collectionReportCount
  return [
    getCollectionReportStats,
    hasRequestedCollectionsData,
    collectionReportCountData,
    collectionReportCountDataLoading,
  ]
}

export function useSpoofingReportCountData() {
  const { spoofingReportCount } = useContext(StatsContext)
  const [
    getSpoofingReportsStats,
    hasRequestedSpoofingData,
    spoofingReportCountData,
    spoofingReportCountDataLoading,
  ] = spoofingReportCount
  return [
    getSpoofingReportsStats,
    hasRequestedSpoofingData,
    spoofingReportCountData,
    spoofingReportCountDataLoading,
  ]
}

export function useEndDate() {
  const { endDateOps } = useContext(StatsContext)
  const [endDate, setEndDate] = endDateOps
  return [endDate, setEndDate]
}

export function useDateOptionIndex() {
  const { dateOptionIndexOps } = useContext(StatsContext)
  const [dateOptionIndex, setDateOptionIndex] = dateOptionIndexOps
  return [dateOptionIndex, setDateOptionIndex]
}

export function useSelectedProduct() {
  const { productOps } = useContext(StatsContext)
  const [selectedProduct, setSelectedProduct] = productOps
  return [selectedProduct, setSelectedProduct]
}

export const dateOptions = [
  [STAT_DATE_UNIT.DAYS, 7],
  [STAT_DATE_UNIT.DAYS, 30],
  [STAT_DATE_UNIT.MONTHS, 3],
  [STAT_DATE_UNIT.MONTHS, 6],
]

const defaultDateOption = [STAT_DATE_UNIT.MONTHS, 6]
const defaultDateOptionIndex: number = dateOptions.findIndex((option) => {
  return option[0] === defaultDateOption[0] && option[1] === defaultDateOption[1]
})

const getTabsForOrg = (isEmployeeView, orgData): Array<TabInfo> => {
  const tabs: TabInfo[] = [
    {
      name: StatsTab.OVERVIEW,
      route: '/analytics/overview',
      isVisible: isEmployeeView,
    },
    {
      name: StatsTab.TRENDS,
      route: '/analytics/trends',
      isVisible: true,
    },
    {
      name: StatsTab.CRED_THEFT,
      route: '/analytics/dashboard',
      isVisible: isEmployeeView || orgData?.name === JPM_ORG_NAME,
    },
    {
      name: StatsTab.REPORTS,
      route: '/reports',
      isVisible: false,
    },
    {
      name: StatsTab.PROCESSING_TIME,
      route: '/analytics/processingtime',
      isVisible: false,
    },
  ]
  return tabs.filter((tabInfo) => tabInfo.isVisible)
}

const getMonthsApart = (date1: Date, date2: Date): number => {
  const momentDate1 = moment.utc(date1)
  const momentDate2 = moment.utc(date2)
  return Math.abs(momentDate1.diff(momentDate2, 'months'))
}

// Utility for culmulative stats to get end-of-month dates between (and including) `startDate` and `endDate`
export const getMonthlyDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const queryDates = [startDate]
  let endOfMonth = moment.utc(startDate).endOf('month')

  while (endOfMonth.isBefore(moment.utc(endDate))) {
    queryDates.push(endOfMonth.toDate())
    endOfMonth = endOfMonth.add(1, 'month').endOf('month')
  }
  queryDates.push(endDate)
  return queryDates
}

export function usePreferredDateUnit() {
  const { preferredDateUnit } = useContext(StatsContext)
  return preferredDateUnit
}

export const StatsContextProvider: FC<{ children }> = ({ children }) => {
  const [orgData] = getOrgData()
  const orgId = orgData?.id
  const [isEmployeeView] = useIsEmployeeView()
  const [endDate, setEndDate] = useState<Date>(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [dateOptionIndex, setDateOptionIndex] = useState(defaultDateOptionIndex)
  const [selectedProduct, setSelectedProduct] = useState('all')
  const endDateRef = useRef(endDate)

  endDateRef.current = endDate

  const preferredDateUnit =
    getMonthsApart(startDate, endDate) >= MONTHLY_AGGREGATION_CUTOFF
      ? STAT_DATE_UNIT.MONTHS
      : STAT_DATE_UNIT.DAYS

  const useMonthlyAggregation = useMemo(
    () => preferredDateUnit === STAT_DATE_UNIT.MONTHS,
    [preferredDateUnit],
  )

  const collectionReportsStatsResult = useGetCollectionReportsStatsV2LazyQuery({
    variables: {
      organization_id: orgId,
      date_from: startDate,
      date_to: endDate,
    },
    fetchPolicy: 'no-cache',
  })

  const collectionReportsStatsFilterResult =
    useGetCollectionReportsStatsV2FilterLazyQuery({
      variables: {
        organization_id: orgId,
        dates: getMonthlyDatesBetween(startDate, endDate),
      },
      fetchPolicy: 'no-cache',
    })

  const [
    getCollectionReportStats,
    {
      called: hasRequestedCollectionsData,
      data: collectionReportCountData,
      loading: collectionReportCountDataLoading,
    },
  ] = useMonthlyAggregation
    ? collectionReportsStatsFilterResult
    : collectionReportsStatsResult

  const spoofingReportsStatsResult = useGetSpoofingReportsStatsLazyQuery({
    variables: {
      organization_id: orgId,
      date_from: startDate,
      date_to: endDate,
    },
    fetchPolicy: 'no-cache',
  })

  const spoofingReportsStatsFilterResult = useGetSpoofingReportsStatsFilterLazyQuery({
    variables: {
      organization_id: orgId,
      dates: getMonthlyDatesBetween(startDate, endDate),
    },
    fetchPolicy: 'no-cache',
  })

  // Destructure and alias the results based on the condition
  const [
    getSpoofingReportsStats,
    {
      called: hasRequestedSpoofingData,
      data: spoofingReportCountData,
      loading: spoofingReportCountDataLoading,
    },
  ] = useMonthlyAggregation
    ? spoofingReportsStatsFilterResult
    : spoofingReportsStatsResult

  const products = getProductsByReportType(selectedProduct)

  const [
    getPlatforms,
    {
      called: hasRequestedPlatformData,
      data: platformsResult,
      loading: platformsDataLoading,
    },
  ] = useGetFilterablePlatformsByProductLazyQuery({
    variables: {
      orgId: orgId,
      products: products,
      reportStatuses: getReportStatuses(isEmployeeView),
    },
  })

  const platformsData =
    platformsResult?.spoofing_reports?.map((platform) => platform.type) || []

  useEffect(() => {
    if (dateOptionIndex === -1) {
      return
    }
    const [unit, value] = dateOptions[dateOptionIndex]
    const unitStr = unit.toString().toLowerCase()
    const unitSingular = unitStr.slice(0, -1)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const date = moment().subtract(value, unitStr).startOf(unitSingular)
    setStartDate(date.toDate())

    if (!moment(endDateRef.current).isSame(moment(), 'day')) {
      setEndDate(new Date())
    }
  }, [dateOptionIndex])

  return (
    <StatsContext.Provider
      value={{
        startDateOps: [startDate, setStartDate],
        endDateOps: [endDate, setEndDate],
        dateOptionIndexOps: [dateOptionIndex, setDateOptionIndex],
        productOps: [selectedProduct, setSelectedProduct],
        collectionReportCount: [
          getCollectionReportStats,
          hasRequestedCollectionsData,
          collectionReportCountData,
          collectionReportCountDataLoading,
        ],
        spoofingReportCount: [
          getSpoofingReportsStats,
          hasRequestedSpoofingData,
          spoofingReportCountData,
          spoofingReportCountDataLoading,
        ],
        platforms: [
          getPlatforms,
          hasRequestedPlatformData,
          platformsData,
          platformsDataLoading,
        ],
        preferredDateUnit: preferredDateUnit,
      }}
    >
      {children}
    </StatsContext.Provider>
  )
}

const Stats = ({ component }) => {
  const [orgData] = getOrgData()
  const [isEmployeeView] = useIsEmployeeView()
  const router = useRouter()
  const [tabs, setTabs] = useState(getTabsForOrg(isEmployeeView, orgData))

  return (
    <PageLayout
      component={component}
      onTabChange={(tabInfo) => {
        router.push(tabInfo.route)
      }}
      tabs={tabs}
    />
  )
}
export default Stats
